// Breakpoint
$tablet:            600px !default;
$desktop:           960px !default;
$widescreen:       1280px !default;
$fullhd:           1500px !default;

// Sizes
$column-gap:         20px !default;
$max-width:        1280px !default;
$radius:             12px !default;


// Colors
$color-main:      #53B232 !default;
$color-heading:   #3D3A39 !default;
$color-txt:          #444 !default;
$color-annotation:   #777 !default;
$color-border:    #C2C2C2 !default;
$color-lightgray: #E9E9E9 !default;

// Accessiblity Colors
$color-orange: #FA6D1C;
$color-red: #D63532;
$color-green: #53B332;
$color-blue: #4954D6;
$color-lightBlue: #37BFFA;

// Animation
$transition-fast:    .15s !default;
$transition-middle:  .3s !default;
$easeInOutCubic:  cubic-bezier(0.65,0.05,0.36,1);

// Path
$pathToImg: "/assets/img/";
