// 既存CSSのie向け記述
.ie section#top,
body {
  -ms-overflow-style: none;  // IEのスクロールバーを非表示にする
}

// アニメーション定義
@keyframes pcNavOpen {
  0% {
    transform: translateY(-8px);
    opacity: 0;
    background-color: #FFF;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    background-color: #F6F7F8;
  }
}

@keyframes pcNavOpenIe {
  0% {
    transform: translateY(-8px);
    opacity: 0;
    background-color: rgba(0,0,0, 0);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    background-color: rgba(0,0,0,.5);
  }
}

// PCまでは畳む
.p-gnavi {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 300px; // スマホからwidescreenまでは横幅固定
  height: 100vh;
  padding-bottom: 160px;
  top: 0;
  right: -300px;
  // .u-shadow-2と同じbox-shadow
  box-shadow: 0px 2px 4px -1px rgba(76, 25, 25, 0.08), 0px 4px 8px 1px rgba(76, 25, 25, 0.06), 0px 2px 8px 2px rgba(76, 25, 25, 0.04);
  background-color: #fff;
  overflow: scroll; // ディスプレイに収まらない時はスクロールできる

  // SP-PC間でリサイズ時に大きくアニメーションするのを防ぐため、PC以外のみアニメーションを適用
  @media screen and (max-width: $widescreen - 1px){
    transition: all $transition-middle $easeInOutCubic;
  }

  // トグルメニューを押すと左へスライド表示
  &.is-opened {
    right: 0;

    // オープン時はオープンボタンの階層を下にして、押せないようにする
    &+.p-gnavi__openBtn {
      z-index: -1;
    }
  }

  // widescreenではナビゲーションを開く
  @include widescreen {
    display: flex;
    flex-direction: row;
    flex-wrap :wrap;
    width: 100%;
    padding: 0;
    box-shadow: none;  // 影をリセット
    position: static;
    height: auto;
    top: auto;
    right: auto;
    background-color: transparent;

    scrollbar-width: none; // Firefoxのスクロールバーを非表示にする
    -ms-overflow-style: none;  // IEのスクロールバーを非表示にする
  }
  &::-webkit-scrollbar {
    @include widescreen {
      display: none; // Chrome, Safari, Edgeのスクロールバーを非表示にする
    }
  }

  // 下層セクションのラッパー（ulタグ）
  &__content {
    width: 100%;
    order: 1;
    flex: 0 0 auto;

    // widescreen
    @include widescreen {
      display: flex;
      flex: 1 0 auto;
      width: auto;
      padding: 0 0 0 20px;
    }

    // 資料請求・お問い合わせセクション
    &--contact {
      order: 0; // 上から数えて、最初に資料請求・お問い合わせセクションが来るようにする
      display: flex;
      flex-direction: column-reverse; // SPでは上に「お問い合わせ」が来るようにする

      // widesceen
      @include widescreen {
        flex-direction: row; // 右に「お問い合わせ」が来るようにする
        order: 2; // 右下に資料請求・お問い合わせセクションが来るようにする
        flex: 0 0 auto;
        width: auto;
      }
    }

    // お役立ち資料・採用情報など優先度低い要素
    &--sub {
      order: 2; // 最下部に「お役立ち資料・採用情報」セクションが来るようにする
      border-bottom: 1px solid $color-lightgray;

      // widescreen
      @include widescreen {
        order: 0; // 右上に「お役立ち資料・採用情報」が来るようにする
        flex: 1 0 auto;
        width: 100%;
        border-bottom: none; // ボーダーをリセット
        justify-content: flex-end;
        margin-bottom: 8px;

        // スクロールしたら畳んで非表示にする（ナビゲーションの高さを短くする）
        .is-scrolled & {
          display: none;
        }
      }
    }

    // 2階層
    &--2nd {
      display: none; // 非表示

      // widescreen
      @include widescreen {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows:repeat(6, 1fr);
        position: absolute;
        width: 100vw;
        top: 88px;
        padding: 24px calc((100vw - #{$max-width} - 40px) / 2);
        left: 0;
        color: $color-main;
        background-color: #F6F7F8;
        opacity: 0;
        display: none;
        z-index: -1;

        // ホバー時はグリッドにする
        &.is-expand {
          display: grid;
          opacity: 1;
          top: 88px;
          box-shadow: 0px 5px 3px -3px rgba(76, 25, 25, 0.07), 0px 6px 4px -4px rgba(76, 25, 25, 0.05), 0px 7px 5px -5px rgba(76, 25, 25, 0.03);

          // アニメーション
          // animation: pcNavOpen .3s ease 0s  normal forwards running;
          // IE11は↑ショートハンドでかくと動かなかったので、↓個別に指定
          animation-name: pcNavOpen;
          animation-duration: .3s;
          animation-timing-function: ease;
          animation-delay: 0s;
          animation-iteration-count: 1;
          animation-direction: normal;
          animation-fill-mode: forwards;
          animation-play-state: running;

          .ie .p-header--top & {
            animation-name: pcNavOpenIe;
          }

          .ie .fixed & {
            animation-name: pcNavOpen;
          }

          // スクロール時はヘッダーの高さが短くなるので、位置を調整する
          .is-scrolled & {
            top: 52px;
          }
        }

        .p-header--top & {
          background-color: rgba(0,0,0,.5) !important;
          .ie & {
            background-color: rgb(0,0,0);
            filter: none;
            opacity: .5;
          }
        }
        .fixed & {
          background-color: #F6F7F8 !important;
        }
      }
    }

    // 2階層のビジネスはgirdではなく、flexboxにする
    &--business {
      @include widescreen {
        padding-bottom: 40px;
        &.is-expand {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    // 2階層の会社情報はgridではなく、flexboxにする
    &--corporate {
      @include widescreen {
        &.is-expand {
          display: flex;
        }
      }
    }

    // 2階層のirは下にパディングを付与
    &--ir {
      @include widescreen {
        padding-bottom: 40px;
      }
    }

    // 3,4階層
    &--3rd,
    &--4th {
      display: none; // 非表示
      @include widescreen {
        padding: 0;
        display: block;
      }
    }

    // インターネット広告
    &--agency {

      // widescreen
      @include widescreen {
        display: flex;
        & > .p-gnavi__item--3rd {
          width: 25%;
        }
      }
    }
  }

  // 各アイテム（liタグ）
  &__item {
    letter-spacing: normal;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-color: #FFF;
    padding: 0;
    border-top: 1px solid $color-lightgray;
    width: 100%;

    // widescreen
    @include widescreen {
      align-items: center;
      flex-wrap: nowrap;
      border-top: none;
      flex: 0 0 auto;
      display: flex;
      width: auto;
      position: static;
      padding: 0 20px;

      // サイトトップページでは背景動画を表示させるため、PCでは背景を透過
      .p-header--top & {
        background-color: transparent;
      }

      // 子要素のあるアイテム（アコーディオンの見出し）
      &.has-child {
        padding: 0 8px 0 20px; // トグルアイコンがあるアイテムはpaddingを調整

        &:hover {

          // ホバー時のアイコンラッパー
          & > .p-gnavi__toggleBtn {
            color: $color-main;
            transform: rotate(180deg); // 180度回転
          }
          // ホバー時のアイコン
          & > .p-gnavi__icon {
            color: $color-main;
          }

          // トップページかつ、ボタン押下後、ホバー時のアイコン
          .fixed > & > .p-gnavi__icon {
            color: $color-main;
          }

          // ホバー時のテキスト
          & > .p-gnavi__txt {
            color: $color-main;

            .p-header--top & {
              color: #FFF; // サイトトップページでは文字色を白に
            }

            .fixed & { // サイトトップかつ、ページ展開（下かっこボタン押下後は通常通りに戻す）
              color: $color-main;
              &--contact {
                color: #FFF;
              }
            }
          }

          & > .p-gnavi__txt--contact {
            color: #FFF;
          }

          & > .p-gnavi__content {
            // display: grid;
            // grid-template-columns: repeat(12, 1fr);
            // grid-template-rows:repeat(6, 1fr);
            // position: absolute;
            // width: 100vw;
            // top: 88px;
            // padding: 24px calc((100vw - #{$max-width}) / 2);
            // left: 0;
            // color: $color-main;
            // background-color: #F6F7F8;
            // .p-header--top & {
            //   background-color: rgba(0,0,0,.5);
            // }
            // .fixed & {
            //   background-color: #F6F7F8;
            // }
          }
        }
      }

      // PCではCSS Cridでレイアウトする
      // 法人向けサービスはflexitem
      &--business {
        @include widescreen {
          &:first-child {
            width: 25%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 25%;
          }
          &:nth-child(4) {
            width: 25%;
          }
          &:nth-child(5) {
            width: 100%;
          }
        }
      }

      // 個人向けサービスは単純に3/12分割
      &--service {
        @include widescreen {
          &:first-child {
            grid-column: 1 / 4;
            grid-row: 1 / 7;
          }
          &:nth-child(2) {
            grid-column: 4 / 7;
            grid-row: 1 / 7;
          }
          &:nth-child(3) {
            grid-column: 7 / 10;
            grid-row: 1 / 7;
          }
          &:nth-child(4) {
            grid-column: 10 / 13;
            grid-row: 1 / 7;
          }
        }
      }
      // 会社情報は5列（割り切れないので左右に余白）
      &--corporate {
        @include widescreen {
          &:first-child {
            width: 20%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 20%;
          }
          &:nth-child(5) {
            width: 20%;
          }
        }
      }
      // ニュースは2列（そのままだと1アイテムあたりの幅が広すぎるので、左右に余白）
      &--news {
        @include widescreen {
          &:first-child {
            grid-column: 4 / 7;
            grid-row: 1 / 7;
          }
          &:nth-child(2) {
            grid-column: 7 / 10;
            grid-row: 1 / 7;
          }
        }
      }
      // IRはメイソンリーレイアウト風
      &--ir {
        @include widescreen {
          &:first-child {
            grid-column: 1 / 4;
            grid-row: 1 / 4;
          }
          &:nth-child(2) {
            grid-column: 4 / 7;
            grid-row: 1 / 4;
          }
          &:nth-child(3) {
            grid-column: 7 / 10;
            grid-row: 1 / 4;
          }
          &:nth-child(4) {
            grid-column: 10 / 13;
            grid-row: 1 / 7;
          }
          &:nth-child(5) {
            grid-column: 1 / 4;
            grid-row: 4 / 7;
          }
          &:nth-child(6) {
            grid-column: 4 / 7;
            grid-row: 4 / 5;
          }
          &:nth-child(7) {
            grid-column: 7 / 10;
            grid-row: 4 / 5;
          }
          &:nth-child(8) {
            grid-column: 4 / 7;
            grid-row: 5 / 6;
          }
          &:nth-child(9) {
            grid-column: 7 / 10;
            grid-row: 5 / 6;
          }
          &:nth-child(10) {
            grid-column: 4 / 7;
            grid-row: 6 / 7;
          }
          &:nth-child(11) {
            grid-column: 7 / 10;
            grid-row: 6 / 7;
          }
        }
      }

      // 資料請求のアイテム
      &--document {
        @include widescreen {
          margin-right: 8px;
        }
      }

      // お問い合わせのアイテム
      &--contact {
        @include widescreen {
          &:first-child {
            grid-column: 1 / 5;
            grid-row: 1 / 7;
          }
          &:nth-child(2) {
            grid-column: 5 / 9;
            grid-row: 1 / 7;
          }
          &:nth-child(3) {
            grid-column: 9 / 13;
            grid-row: 1 / 7;
          }
        }
      }
    }

    // ホームへのリンクはPCでは非表示
    &--home {
      @include widescreen {
        display: none;
      }
    }

    // 第2階層のアイテム
    &--2nd {
      @include widescreen {
        background-color: transparent;
        display: block;
        width: 100%;
        padding: 0 20px;
      }
    }

    // 第3階層のアイテム
    &--3rd {
      @include widescreen {
        background-color: transparent;
        display: block;
        padding: 0 0 0 12px;
        margin-right: 10px;
      }
    }

    // 第4階層のアイテム
    &--4th {
      @include widescreen {
        background-color: transparent;
        display: block;
        padding: 1px 0 1px 12px;
      }
    }

    // サブ要素（お役立ち資料・採用情報・サイトマップ）は余白をなしに
    &--sub {
      @include widescreen {
        padding: 0;
      }
    }

    // コール・トゥ・アクションのスタイル（リニューアル後用）
    // &--cta {
    //   border-top: none;
    //   padding: 8px 20px;
    //   &:first-child {
    //     margin-bottom: 16px;
    //   }
    //   @include widescreen {
    //     padding: 0;
    //     &:first-child {
    //       margin: 0;
    //     }
    //   }
    // }

    // お問い合わせ関連のアイテム
    &--contact {
      &.has-child {
        @include widescreen {
          border-radius: 4px 4px 0 0;
          background-color: $color-main;
          padding: 0 8px 0 20px;

          // スクロールしたら角丸をとり
          .is-scrolled & {
            border-radius: 0;
          }

          // ホームのファーストビューでは背景なし
          .p-header--top & {
            background-color: transparent;
          }

          // ホームのボタン押下後は背景あり（デフォルト）
          .fixed & {
            background-color: $color-main;
          }
        }
      }
    }
  }
  // /.p-gnabi__item


  // リンク文言（aタグ）
  &__txt {
    // reset
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;

    font-size: 15px;
    color: $color-heading;
    padding: 0 20px;
    display: block;
    flex: 1 0 auto;
    width: auto;
    line-height: 52px;
    // テキストを折り返さない
    word-break: keep-all;
    transition: .3s;

    // ieは読み込み時にアニメーションが効いてしまうので無効化
    .ie & {
      transition: 0;
    }

    @include widescreen {
      flex: 0 0 auto;
      padding: 0;
      // // トップは背景が暗いため、PCでは文字色を白に
      .p-header--top & {
        color: #FFF;
      }
      // スクロール後は下に戻す
      .fixed & {
        color: $color-heading;
      }
    }

    // アコーディオンが開いている時のスタイル
    &.is-expand {
      background-color: $color-main;
      color: #FFF;
    }

    // サブ要素（お役立ち資料・採用情報・サイトマップ）はPCで小さく
    &--sub {
      @include widescreen {
        font-size: 13px;
        line-height: 20px;
        padding: 0;
        margin-left: 20px;
        color: $color-annotation;
      }
    }

    // 現在地のアイテムを装飾
    &.is-current {
      position: relative;
      // SPでは左にドット
      &:after {
        content: "";
        position: absolute;
        display: block;
        background-color: $color-main;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        left: 6px;
        top: 50%;
        margin-top: -3px;
      }
      // PCでは色を変更
      @include widescreen {
        color: $color-main;
        &:after {
          // リセット
          content: none;
        }

        // 現在地のアイコンは緑色にする
        & + .p-gnavi__toggleBtn > .p-gnavi__icon {
          color: $color-main;
        }

        &.p-gnavi__txt--contact {
          color: #FFF;
        }
      }
    }

    // 第2階層のリンク（aタグ）
    &--2nd {
      font-size: 14px;
      line-height: 20px;
      padding: 16px 16px 16px 32px;
      // アコーディオンが開いている時のスタイル（第2階層）
      &.is-expand {
        background-color: #F6F7F8;
        color: $color-main;
      }
      @include widescreen {
        font-weight: bold;
        font-size: 15px;
        padding: 12px 12px 12px 20px;
        border-bottom: 1px solid $color-lightgray;
      }
    }

    // 第3階層のリンク（aタグ）
    &--3rd {
      font-size: 13px;
      line-height: 20px;
      padding: 16px 16px 16px 44px;
      // アコーディオンが開いている時のスタイル（第2階層）
      &.is-expand {
        background-color: #fff;
        color: $color-txt;
      }
      @include widescreen {
        padding: 8px;
        font-size: 14px;
      }
    }

    // 第5階層のリンク（aタグ）
    &--4th {
      font-size: 12px;
      line-height: 20px;
      padding: 16px 16px 16px 56px;
      position: relative;
      @include widescreen {
        padding: 4px 8px;
        font-size: 13px;
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 8px;
          height: 4px;
          background-color: $color-lightgray;
          border-radius: 2px;
          top: 50%;
          left: -8px;
          margin-top: -2px;
        }
      }
    }

    // 第2,3,4階層のリンク
    &--2nd,
    &--3rd,
    &--4th {
      @include widescreen {
        &:hover {
          background-color: rgba(#fff, .8);
          border-radius: 4px;
        }
      }
      // ホームでは白黒反転のため背景色を変更
      .p-header--top & {
        @include widescreen {
          &:hover {
            background-color: rgba(#fff, .1);
            border-radius: 4px;
          }
        }
      }
      // ホームでボタン押下後は元に戻す
      .fixed & {
        @include widescreen {
          &:hover {
            background-color: rgba(#fff, .8);
            border-radius: 4px;
          }
        }
      }
    }


    // コール・トゥ・アクションのスタイル（リニューアル後用）
    // &--document {
    //   font-size: 14px;
    //   padding: 12px 24px;
    //   line-height: 1.5;
    //   background-color: #eee;
    //   border-radius: 24px;
    //   &:hover {
    //     color: $color-txt;
    //     opacity: .8;
    //   }
    //   @include widescreen {
    //     padding: 8px 24px;
    //   }
    // }

    // お問い合わせリンク
    &--contact {
      font-size: 14px;
      // padding: 12px 24px;
      // line-height: 1.5;
      // background-color: $color-main;
      // color: #FFF;
      // border-radius: 24px;
      @include widescreen {
        // margin-left: 12px;
        padding: 0px;
        // background-color: $color-main;
        border-radius: 24px;
        color: #FFF;
        line-height: 1.5;
        &:hover {
          color: #FFF;
          opacity: .8;
        }
      }
      .fixed & {
        @include widescreen {
          color: #FFF;
        }
      }
    }

    &--agency {
      // 広告代理事業のみ特別に幅25%
      @include widescreen {
        width: 25%;
      }
    }
  }

  // トグルアイコンのラッパー
  &__toggleBtn {
    // reset
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;

    flex: 0 0 auto;
    width: 52px;
    height: 52px;
    transition: .3s;
    @media screen and (max-width: $widescreen - 1px){
      border-left: 1px solid rgba(0,0,0,.08);
    }
    // ieは時々アニメーションに不具合があるので無効化
    .ie & {
      transition: 0;
    }

    // アコーディオンを開いている時のスタイル
    &.is-expand {
      background-color: $color-main;
      color: #FFF;
      // トグルアイコン
      & .p-gnavi__icon {
        transform: rotate(180deg);

        &--1st {
          @media screen and (max-width: $widescreen - 1px){
            color: #FFF
          }
        }
      }
    }

    @include widescreen {
      padding: 0;
      width: 24px;
      transition: .5s;
    }

    // トグルアイコンのラッパー（第2階層）
    &--2nd {
      @include widescreen {
        display: none;
      }
      // アコーディオンを開いている時のスタイル（第2階層）
      &.is-expand {
        background-color: #F6F7F8;
        color: $color-main;
      }
    }

     // トグルアイコンのラッパー（第3階層）
    &--3rd {
      @include widescreen {
        display: none;
      }
      // アコーディオンを開いている時のスタイル（第3階層）
      &.is-expand {
        background-color: #fff;
        color: $color-txt;
      }
    }
  }

  // トグルアイコン
  &__icon {
    font-size: 14px;
    transition: .3s;
    color: $color-annotation;
    @include widescreen {
      // トップは背景に動画あるためPCで文字色を白にする
      .p-header--top & {
        color: #FFF;
      }
      // スクロール後は下に戻す
      .fixed & {
        color: $color-annotation;
      }
    }
    // お問い合わせのトグルアイコン
    &--contact {
      @include widescreen {
        color: #FFF;
        // スクロール後は下に戻す
        .fixed & {
          color: #FFF;
        }
      }
    }
  }

  // ナビゲーションを閉じるアイコンのラッパー
  &__closeBtn {
    // reset
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    position: relative;

    display: block;
    width: 48px;
    height: 52px;
    margin-left: auto;
    flex: 0 0 auto;

    // PCでは非表示
    @include widescreen {
      display: none;
    }
  }

  // ナビゲーションを閉じるアイコン
  &__closeIcon {
    width: 20px;
    height: 2px;
    display: block;
    margin: 0 auto;
    background-color: $color-txt;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 1px;
    margin-top: -1px;
    margin-left: -10px;
    transform: rotate(45deg);
    &:after {
      content: "";
      width: 20px;
      height: 2px;
      display: block;
      margin: 0 auto;
      background-color: $color-txt;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 1px;
      margin-top: -1px;
      margin-left: -10px;
      transform: rotate(-90deg);
    }
  }

  // メニューを開いた時にhtmlに付与して、背景をスクロールしないようにする。
  &__scrollPrevent {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    right: 0;
    // topの値はjQueryで取得する

    // PCでは設定をリセットする
    @include widescreen {
      position: static;
      z-index: 0;
    }
  }

  // ハンバーガーメニューのラッパー
  &__openBtn {
    // reset
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    position: relative;
    flex: 0 0 auto;

    width: 52px;
    height: 52px;
    margin-left: auto;

    // PCでは非表示
    @include widescreen {
      display: none;
    }
  }

  // ハンバーガーアイコン
  &__openIcon {
    z-index: -1;
    width: 18px;
    height: 2px;
    display: block;
    margin: 0 auto;
    background-color: $color-txt;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 1px;
    margin-top: -1px;
    margin-left: -9px;
    // トップは背景に動画あるため色を白にする
    .p-header--top & {
      background-color: #FFF;
    }
    // スクロール後は元に戻す
    .p-header--top.fixed & {
      background-color: $color-txt;
    }
    &:before {
      content: "";
      width: 18px;
      height: 2px;
      display: block;
      margin: 0 auto;
      background-color: inherit;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 1px;
      margin-top: -6px;
      margin-left: -9px;
    }
    &:after {
      content: "";
      width: 18px;
      height: 2px;
      display: block;
      margin: 0 auto;
      background-color: inherit;
      position: absolute;
      bottom: 50%;
      left: 50%;
      border-radius: 1px;
      margin-bottom: -6px;
      margin-left: -9px;
    }
    @include widescreen {
      display: none;
    }
  }

  // 背景に敷くオーバーレイ
  &__overlay {
    transition: all $transition-middle $easeInOutCubic;
    .p-gnavi__scrollPrevent & {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255,255,255,.8);
      z-index: -1;
    }
  }

  // 別タブで開く（target="_blank"）時のアイコン
  &__newTabIcon {
    font-size: .8em;
    color: $color-annotation;
    margin-left: 8px;
  }
}
