.p-header {

  // 既存サイトに合わせるためのリセット。リニューアル後は不要
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }

	width: 100%;
	padding: 0 0 0 20px;
  position: fixed;
  top: 0;
	background-color: #FFF;
  z-index: 100;
  // .u-shadow-1と同じシャドウ（extendすると詳細度が上がって上書きできないので、直書きする）
  box-shadow: 0px 1px 2px -1px rgba(76, 25, 25, 0.08), 0px 2px 4px 1px rgba(76, 25, 25, 0.06), 0px 1px 4px 1px rgba(76, 25, 25, 0.04);
  @include tablet {
    padding: 0 0 0 20px;
  }
  @include widescreen {
    padding: 8px 24px 0;
    transition: .15s;

    // ieは読み込み時にアニメーションが効いてしまうので無効化
    .ie & {
      transition: 0;
    }
  }

  // スクロールしたら低くする（ワイドスクリーン以上）
  &.is-scrolled {
    @include widescreen {
      padding: 0 24px;
    }
  }

  // TOPページ用のスタイル
  &--top {
    background-color: transparent;
    position: absolute;
    box-shadow: none;
    // fixedヘッダー分の高さを空けない（リセット）
    & + #fixed {
      margin-top: 0;
      @include widescreen {
        margin-top: 0;
      }
    }
  }

  &__inner {
    max-width: 1280px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
  }

  // logoを囲むタグ
  &__logoWrap {
    display: flex;
    align-items: center;
    font-size: 0;
    line-height: 1;
    margin: 0;
    padding: 0;
    @include widescreen {
      display: block;
      margin-top: 24px;
      .is-scrolled & {
        margin-top: 6px;
      }
    }

    .p-gnavi__scrollPrevent & {
      z-index: -1;
    }
  }

  // h1
  &__title {
    width: 112px;
    margin: 2px 0 0;

    // 旧トップと合わせるためbaseのスタイルを明示
    font-size: 24px;
    font-weight: normal;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: .05em;
    line-height: 1.333333;
    // 旧トップ用ここまで
    @include widescreen {
      width: 136px;
      margin: 4px 0 0;
      // 旧トップと合わせるためbaseのスタイルを明示
      font-size: 32px;
      line-height: 1.375;
      // 旧トップ用ここまで
      .is-scrolled & {
        margin: -2px 0 0;
      }
    }
  }

  // eoleロゴのラッパー
  &__logo {
    order: 0;
    width: 112px;
    height: 28px;
    flex: 1 0 auto;
    // @include tablet {
    //   order: 1;
    // }
    @include widescreen {
      width: 136px;
      height: 34px;
      margin: 0;
    }

    &--e {
      fill: $color-main;
      .p-header--top & {
        fill: #FFF;
      }
      .p-header--top.fixed & {
        fill: $color-main;
      }
    }

    &--ole {
      fill: #040000;
      .p-header--top & {
        fill: #FFF;
      }
      .p-header--top.fixed & {
        fill: #040000;
      }
    }

    &--inc {
      fill: $color-main;
      .p-header--top & {
        fill: #FFF;
      }
      .p-header--top.fixed & {
        fill: $color-main;
      }
    }
  }

  // eoleロゴのsvg画像
  &__logoImg {
    line-height: 0;
    font-size: 0;
    width: 112px;
    height: 28px;
    // transition: .15s;
    @include widescreen {
      width: 136px;
      height: 34px;
    }
    .is-scrolled & {
      @include widescreen {
        width: 112px;
        height: 28px;
      }
    }
  }

  // タグライン（新しい便利、よろこびをつくる。）
  &__tagline {
    width: auto;
    max-width: 1280px;
    margin: 0 0 0 12px;
    font-size: 10px;
    line-height: 1.4;
    order: 1;
    flex: 0 1 auto;
    color: $color-heading;
    // トップは背景に動画あるためPCで文字色を白にする
    .p-header--top & {
      color: #FFF;
    }
    // スクロール後は下に戻す
    .p-header--top.fixed & {
      color: $color-heading;
    }
    & br {
      display: inline-block;
      @include tablet {
        display: none;
      }
    }
    @media screen and (min-width: 340px) {
      font-size: 12px;
    }
    @include widescreen {
      margin: 0 auto 12px;
      position: absolute;
      top: 14px;
      max-width: 320px;
      opacity: 1;
      height: auto;
      .is-scrolled & {
        display: none;
        opacity: 0;
        height: 0;
      }
    }
  }

  // fixedヘッダー分の高さを空ける
  & + * {
    margin-top: 52px;
    @include widescreen {
      margin-top: 88px;
    }
  }
}
