$mdc-elevation-baseline-color: #4C1919 !default;
$mdc-elevation-umbra-opacity: .08 !default;
$mdc-elevation-penumbra-opacity: .06 !default;
$mdc-elevation-ambient-opacity: .04 !default;

$mdc-elevation-umbra-map: (
  0: 0px 0px 0px 0px,
  1: 0px 1px 2px -1px,
  2: 0px 2px 4px -1px,
  3: 0px 3px 6px -2px,
  4: 0px 4px 8px -2px,
  5: 0px 5px 10px -3px,
  6: 0px 6px 12px -3px,
  7: 0px 7px 14px -4px,
  8: 0px 8px 16px -4px,
  9: 0px 9px 18px -5px,
  10: 0px 10px 20px -5px
) !default;

$mdc-elevation-penumbra-map: (
  0: 0px 0px 0px 0px,
  1: 0px 2px 4px 1px,
  2: 0px 4px 8px 1px,
  3: 0px 6px 12px 2px,
  4: 0px 8px 16px 2px,
  5: 0px 10px 20px 3px,
  6: 0px 12px 24px 3px,
  7: 0px 14px 28px 4px,
  8: 0px 16px 32px 4px,
  9: 0px 18px 36px 5px,
  10: 0px 20px 40px 5px
) !default;

$mdc-elevation-ambient-map: (
  0: 0px 0px 0px 0px,
  1: 0px 1px 4px 1px,
  2: 0px 2px 8px 2px,
  3: 0px 3px 12px 3px,
  4: 0px 4px 16px 4px,
  5: 0px 5px 20px 5px,
  6: 0px 6px 24px 6px,
  7: 0px 7px 28px 7px,
  8: 0px 8px 32px 8px,
  9: 0px 9px 36px 9px,
  10: 0px 10px 40px 10px
) !default;


@for $i from 0 through 10 {
  .u-shadow-#{$i} {
    box-shadow: map-get($mdc-elevation-umbra-map, $i) rgba($mdc-elevation-baseline-color, $mdc-elevation-umbra-opacity), map-get($mdc-elevation-penumbra-map, $i) rgba($mdc-elevation-baseline-color, $mdc-elevation-penumbra-opacity), map-get($mdc-elevation-ambient-map, $i) rgba($mdc-elevation-baseline-color, $mdc-elevation-ambient-opacity);
  }
}

// The css property used for elevation. In most cases this should not be changed. It is exposed
// as a variable for abstraction / easy use when needing to reference the property directly, for
// example in a `will-change` rule.
$mdc-elevation-property: box-shadow !default;

// The default duration value for elevation transitions.
$mdc-elevation-transition-duration: 280ms !default;
